import { AppEnvironment } from '../models/common/environment/environment';
import { environment } from './environment';

let appEnvironment: AppEnvironment;

export function makeEnvironment() {
  if (!appEnvironment) {
    appEnvironment = new AppEnvironment();
    appEnvironment.ENV_NAME = environment.name;
    appEnvironment.PROJECT_NAME = environment.projectName;
    appEnvironment.PROJECT_VERSION = environment.projectVersion;
    appEnvironment.PROJECT_BUILD_DATE = Number(environment.projectBuildDate);
    appEnvironment.OS_INTERFACE_URL = environment.osInterfaceURL;
    appEnvironment.AUTOPAG_URL = environment.autopagURL;
    appEnvironment.PRINTER_URL = environment.printerURL;
    appEnvironment.DEVICE_URL = environment.deviceURL;
    appEnvironment.SCANNER_CONFIG = environment.scannerConfig;
  }
  return appEnvironment;
}

console.log(makeEnvironment());
