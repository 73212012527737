import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
  ViewContainerRef,
} from '@angular/core';
import { makeIframeCommunicationService } from 'src/data/services/common/iframe-communication/iframe-communication-service-factory';
import { ActionIFrame } from '../data/services/common/iframe-communication/iframe-communication-service-impl';
import { addEventListenerClicksOnScreen } from './helpers/clicks-on-screen';
import { CustomInjector } from './helpers/custom-injector';
import { DynamicComponent } from './helpers/dynamic-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private iframeCommunicationService = makeIframeCommunicationService();

  constructor(
    public injector: Injector,
    public viewContainerRef: ViewContainerRef,
  ) {
    DynamicComponent.setViewContainerRef(this.viewContainerRef);
    CustomInjector.setup(this.injector);

    addEventListenerClicksOnScreen();
    this.monitorUserActivity();
    this.nofityScannerIsLoaded();
  }

  @HostListener('window:mousedown')
  @HostListener('window:keydown')
  monitorUserActivity() {
    this.iframeCommunicationService.sendToParent({
      action: ActionIFrame.USER_ITERACTED_WITH_THE_APP,
    });
  }
  nofityScannerIsLoaded() {
    this.iframeCommunicationService.sendToParent({
      action: ActionIFrame.SCANNER_IS_LOADED,
    });
  }
}
