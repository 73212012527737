import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { makeKeyValueRepository } from '../../data/repositories/key-value/key-value-repository-factory';
import { makeCookieService } from '../../data/services/common/cookie/cookie-service-factory';
import { makeRouterService } from '../../data/services/view/router/router-service-factory';
import { makeEnvironment } from '../../environments/make-environment';
import { CookieKey } from '../../models/common/cookie-key/cookie-key';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public routerService = makeRouterService();
  public keyValueRepository = makeKeyValueRepository();
  public cookieService = makeCookieService();
  public environment = makeEnvironment();

  async canActivate(): Promise<boolean> {
    const accessToken = this.cookieService.get(CookieKey.DEVICE_ACCESS_TOKEN);
    const deviceURL = this.environment.DEVICE_URL;
    if (!accessToken) {
      window.location.href = `${deviceURL}/device/auth`;
      return false;
    }

    return true;
  }
}
