import { IIframeCommunicationService } from './iframe-communication-service';
import { IframeCommunicationServiceImpl } from './iframe-communication-service-impl';

let iframeCommunicationService: IIframeCommunicationService;

export function makeIframeCommunicationService() {
  if (!iframeCommunicationService) {
    iframeCommunicationService = new IframeCommunicationServiceImpl();
  }
  return iframeCommunicationService;
}
