import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export class CustomInjector {
  static router: Router;
  static injector: Injector;
  static activatedRoute: ActivatedRoute;

  static setup(injector: Injector) {
    this.injector = injector;
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
  }
}
