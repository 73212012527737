/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Injector,
} from '@angular/core';
import { makeRouterService } from '../../../../data/services/view/router/router-service-factory';

import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import { Action, ActionType } from '../../../../models/common/action/action';
import { DynamicComponent } from '../../../helpers/dynamic-component';
import { SuperDynamicComponent } from '../../../helpers/super-dynamic-component';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutComponent
  extends SuperDynamicComponent
  implements DynamicComponent, AfterContentInit
{
  public routerService = makeRouterService();

  public message = LanguageKey.MESSAGE_TIMEOUT_SCREEN;
  public actions: Action[] = [
    { desc: LanguageKey.I_AM_HERE, type: ActionType.SUCCESS },
    { desc: LanguageKey.GO_HOME, type: ActionType.BASIC },
  ];
  public timeoutRef: ReturnType<typeof setTimeout>;
  public percent = 100;
  public seconds = '30';
  public intervalRef: ReturnType<typeof setInterval>;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngAfterContentInit(): void {
    this.timeoutRef = setTimeout(() => this.timeout(), 30000);

    let milleseconds = 30000;
    const timeUpdateView = 100;
    this.intervalRef = setInterval(async () => {
      milleseconds -= timeUpdateView;
      if (milleseconds <= 0) {
        return this.timeout();
      }
      this.percent = (milleseconds * 100) / 30000;
      this.seconds = (milleseconds / 1000).toFixed(0);
      this.detectChanges();
    }, timeUpdateView);

    if (globalThis.isReceiptMethodsOpen) {
      this.message =
        LanguageKey.MESSAGE_TIMEOUT_SCREEN_WHEN_RECEIPT_METHOD_OPEN;

      this.actions.pop();
    }
    this.detectChanges();
  }

  clearTimeout() {
    clearTimeout(this.timeoutRef);
    clearInterval(this.intervalRef);
    this.detectChanges();
  }

  continue() {
    this.clearTimeout();
    this.close();
  }

  async timeout() {
    if (
      (globalThis.paymentInProgress || !globalThis.totemInUse) &&
      !globalThis.isReceiptMethodsOpen
    ) {
      return this.continue();
    }
    await this.logService.info('timeout na aplicação');
    this.clearTimeout();
    // TODO: implementar cancel para order
    // await this.orderService.cancel();
    this.routerService.navigate('/');
    this.close();
  }

  onClickAction(action: Action) {
    if (action.type === ActionType.SUCCESS) {
      this.continue();
    } else {
      this.timeout();
    }
  }
}
