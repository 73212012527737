/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  debounce,
  fromEvent,
  interval,
} from 'rxjs';

import { IIframeCommunicationService } from './iframe-communication-service';

export enum ActionIFrame {
  ENABLE_TIMEOUT = 'enable_timeout',
  SCANNER_COMPLETED = 'scanner_completed',
  USER_ITERACTED_WITH_THE_APP = 'user-itereacted-with-the-app',
  SCANNER_IS_LOADED = 'scanner_is_loaded',
}

@Injectable({ providedIn: 'root' })
export class IframeCommunicationServiceImpl
  implements IIframeCommunicationService, OnDestroy
{
  constructor() {
    this.subscribeToMessages();
  }

  private incomingMessage$$ = new Subject<any>();
  private subscription: Subscription | null = null;

  public sendToParent(data: unknown): void {
    this.incomingMessage$$.next(data);
  }

  public receivesFromParent(): Observable<any> {
    return fromEvent(window, 'message');
  }

  private subscribeToMessages() {
    this.subscription = this.incomingMessage$$
      .pipe(debounce(() => interval(100)))
      .subscribe((v) => {
        window.parent.postMessage(v, '*');
      });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
