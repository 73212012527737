import pkg from '../../package.json';
import { EnvironmentName } from '../models/common/environment/environment';

export const environment = {
  name: EnvironmentName.DEV,
  projectName: pkg.name,
  projectVersion: pkg.version,
  projectBuildDate: Date.now(),
  osInterfaceURL: 'http://localhost:3300',
  autopagURL: 'http://localhost:3001',
  printerURL: 'http://localhost:3000',
  deviceURL: 'https://device.homolog.vsd.app/#',
  scannerConfig: 'https://cdn.vsd.app/vsd-device/data/config',
};
